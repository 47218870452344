import React from 'react';
import { IconProps } from './types';

const ChevronRightIcon = ({
  className,
  width = '24',
  height = '24',
  onClick,
}: IconProps) => {
  return (
    <div onClick={onClick} role={onClick ? 'button' : undefined}>
      <React.Suspense fallback={null}>
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          className={className}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18L15 12L9 6"
            stroke="#788598"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </React.Suspense>
    </div>
  );
};

export default ChevronRightIcon;
