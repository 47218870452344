import { ReactNode } from 'react';

function autoCapitalize(
  node: ReactNode,
  capitalize: boolean = true
): ReactNode {
  if (typeof node !== 'string' || node.length === 0 || !capitalize) {
    return node;
  }

  return `${node[0].toUpperCase()}${node.slice(1)}`;
}

export default autoCapitalize;
