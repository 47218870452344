import React from 'react';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
import autoCapitalize from './autoCapitalize';

export enum TextType {
  DISPLAY_L = 'DISPLAY_L',
  DISPLAY_M = 'DISPLAY_M',
  DISPLAY_S = 'DISPLAY_S',
  PAGE_HEADING = 'PAGE_HEADING',
  HEADING_L = 'HEADING_L',
  HEADING_M = 'HEADING_M',
  HEADING_S = 'HEADING_S',
  SUBHEADING = 'SUBHEADING',
  BODY_MICO = 'BODY_MICO',
  BODY_S = 'BODY_SMALL',
  BODY_M = 'BODY_MEDIUM',
  BODY_L = 'BODY_LARGE',
  BODY_HIGHLIGHT = 'BODY_HIGHLIGHT',
  LABEL = 'LABEL',
  BUTTON = 'BUTTON',
  UI_ELEMENT = 'UI_ELEMENT',
  LOZENGE = 'LOZENGE',
}

interface TextProps {
  type: TextType;
  disabled?: boolean;
  subdued?: boolean;
  destructive?: boolean;
  forceCapitalization?: boolean;
  className?: string;
  testId?: string;
  children: React.ReactNode;
}

export const Text = ({
  type,
  disabled,
  subdued,
  forceCapitalization = true,
  className,
  children,
  testId,
}: TextProps) => {
  let color = 'text-defaultTextColor';
  if (subdued) color = 'text-subduedTextColor';
  if (disabled) color = 'text-disabledTextColor';

  if (type === TextType.DISPLAY_L) {
    return (
      <h1
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-display',
          'text-displayLSize',
          'leading-displayL',
          'tracking-display',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </h1>
    );
  }

  if (type === TextType.DISPLAY_M) {
    return (
      <h2
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-display',
          'text-displayMSize',
          'leading-displayM',
          'tracking-display',
          'cursor-default',
          className
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </h2>
    );
  }

  if (type === TextType.DISPLAY_S) {
    return (
      <h3
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-display',
          'text-displaySSize',
          'leading-displayS',
          'tracking-display',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </h3>
    );
  }

  if (type === TextType.PAGE_HEADING) {
    return (
      <h4
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-pageheading',
          'leading-pageheading',
          'tracking-pageheading',
          'cursor-default',
          'text-pageheadingSize'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </h4>
    );
  }

  if (type === TextType.HEADING_L) {
    return (
      <h5
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-heading',
          'text-headingLSize',
          'leading-headingL',
          'tracking-heading',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </h5>
    );
  }

  if (type === TextType.HEADING_M) {
    return (
      <h5
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-heading',
          'text-headingMSize',
          'leading-headingM',
          'tracking-heading',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </h5>
    );
  }

  if (type === TextType.HEADING_S) {
    return (
      <h5
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-heading',
          'leading-headingS',
          'text-headingSSize',
          'tracking-heading',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </h5>
    );
  }

  if (type === TextType.SUBHEADING) {
    return (
      <h6
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-subheading',
          'text-subheadingSize',
          'leading-subheading',
          'tracking-subheading',
          'uppercase',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </h6>
    );
  }

  if (type === TextType.BODY_MICO) {
    return (
      <p
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-body',
          'text-bodyMicoSize',
          'leading-bodyMico',
          'tracking-body',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </p>
    );
  }

  if (type === TextType.BODY_S) {
    return (
      <p
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-body',
          'text-bodySSize',
          'leading-bodyS',
          'tracking-body',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </p>
    );
  }

  if (type === TextType.BODY_M) {
    return (
      <p
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-body',
          'text-bodyMSize',
          'leading-bodyM',
          'tracking-body',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </p>
    );
  }

  if (type === TextType.BODY_L) {
    return (
      <p
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-body',
          'text-bodyLSize',
          'leading-bodyL',
          'tracking-body',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </p>
    );
  }

  if (type === TextType.BODY_HIGHLIGHT) {
    return (
      <p
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-highlight',
          'text-highlightSize',
          'leading-highlight',
          'tracking-highlight',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </p>
    );
  }

  if (type === TextType.LABEL) {
    return (
      <label
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-label',
          'text-labelSize',
          'leading-label',
          'tracking-label',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </label>
    );
  }

  if (type === TextType.BUTTON) {
    return (
      <p
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-button',
          'text-buttonSize',
          'leading-button',
          'tracking-button',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </p>
    );
  }

  if (type === TextType.UI_ELEMENT) {
    return (
      <p
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-element',
          'text-elementSize',
          'leading-element',
          'tracking-element',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </p>
    );
  }

  if (type === TextType.LOZENGE) {
    return (
      <p
        data-testid={testId}
        className={classnames(
          twMerge(color, className),
          'font-lozenge',
          'text-lozengeSize',
          'leading-lozenge',
          'tracking-lozenge',
          'uppercase',
          'cursor-default'
        )}
      >
        {autoCapitalize(children, forceCapitalization)}
      </p>
    );
  }

  return (
    <span data-testid={testId}>
      {autoCapitalize(children, forceCapitalization)}
    </span>
  );
};
